
import { defineComponent, ref, onMounted, computed } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useRoute } from "vue-router";
import moment from "moment";
import Datepicker from "vue3-datepicker";
import router from "@/router";
import store from "@/store";
import { addRole, getRoleById, roleObjClone } from "@/core/data/role";
import PartyMultiselect from "@/components/all-multiselect/party-multiselect.vue";
import { getError, getSuccess } from "@/core/helpers/utils";
export default defineComponent({
  name: "purchase-request-form",
  components: {
    Datepicker,
    PartyMultiselect,
  },
  setup() {
    const route = useRoute();
    const formRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);
    const tab2 = ref<any>(null);
    const file = ref<any>(null);
    const multiForm = ref<any>({});
    const fileError = ref<any>("");
    const po_id = ref<any>("");
    const purchase_order = ref<any>({});
    const selectedTab = ref<any>("Details");
    const isEdit = ref<boolean>(true);
    const isAdd = ref<boolean>(true);
    const universityList = ref<Array<any>>([]);
    const formData = ref(roleObjClone());
    const rules = ref({
      date: [
        {
          required: true,
          message: "Date is required",
          trigger: "change",
        },
      ],
      pr_no: [
        {
          required: true,
          message: "Invoice Number is required",
          trigger: "change",
        },
      ],
    });
    onMounted(() => {
      multiForm.value = {
        multiple_mobile: [""],
        multiple_email: [""],
      };
      formData.value = roleObjClone();
      console.log("route.params.id", route.params.id);
      console.log(route.query.feeId);
      if (route.params.id) {
        isEdit.value = false;
        isAdd.value = false;
        getItemData();
      }
    });
    const setEdit = (status) => {
      isEdit.value = status;
    };
    const getItemData = () => {
      getRoleById(route.params.id).then((roleItem: any) => {
        formData.value = {
          ...roleItem,
        };
      });
    };

    const setSelectedTab = (name) => {
      selectedTab.value = name;
      getItemData();
    };
    const checkMaxSize = () => {
      fileError.value = "";
      if (file.value?.files[0]?.size > 1024 * 500) {
        fileError.value = "Max Size Should be 500kb";
      }
    };
    const submit = () => {
      if (!formRef.value) {
        return;
      }
      checkMaxSize();
      formRef.value.validate((valid) => {
        if (valid && !fileError.value) {
          loading.value = true;
          let reqObj: any = { ...formData.value };
          reqObj.date = moment(reqObj.date).format();
          if (!isAdmin.value) {
            delete reqObj.site;
          }
          console.log(file.value);
          addRole(reqObj, file.value?.files, route.params.id)
            .then((responseData) => {
              loading.value = false;

              Swal.fire({
                text: getSuccess(
                  responseData,
                  "Form has been successfully submitted!"
                ),
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                setTimeout(() => {
                  if (!route.params.id) {
                    router.push({ name: "apps-role-listing" });
                  } else {
                    isEdit.value = false;
                    getItemData();
                  }
                }, 200);
              });
            })
            .catch((err) => {
              loading.value = false;
              Swal.fire({
                html: getError(err),
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
            });
        } else {
          Swal.fire({
            text: "Sorry, looks like there are some errors detected, please try again.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          return false;
        }
      });
    };
    const handleInput = (e) => {
      console.log("input");
    };
    const universityListComputed = computed(() => {
      return universityList.value.map((item) => ({
        ...item,
      }));
    });
    const purchase_request = computed(() => {
      return route.params.id;
    });
    const userData = computed(() => {
      return store.getters.currentUser;
    });
    const isAdmin = computed(() => {
      return ["ADMIN"].indexOf(userData.value.role) > -1;
    });
    return {
      isEdit,
      setEdit,
      formData,
      rules,
      formRef,
      submit,
      loading,
      file,
      moment,
      isAdd,
      handleInput,
      universityListComputed,
      isAdmin,
      po_id,
      purchase_request,
      setSelectedTab,
      selectedTab,
      purchase_order,
      tab2,
      multiForm,
      checkMaxSize,
      fileError,
    };
  },
});
